import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';

import Layout from 'layout/Layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import { TagEventTracker } from 'components/TagEventTracker';

import { FrameProps } from './models';

import './Frame.scss';

const Frame: FC<{ data: FrameProps }> = ({
  data: {
    frame: { title, src, id, urls, height, cssExtraClass, width, seo, cssExtraClassParent },
  },
}) => {
  const { title: seoTitle, keywords: seoKeywords, description: seoDescription } = seo;
  const location = useLocation();

  if (!src) return null;

  return (
    <Layout headerTransparent>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: seoDescription,
          metaKeywords: seoKeywords,
        }}
      />
      <TagEventTracker eventType="page_view" data={{ pageTemplate: location.pathname }}>
        <div className={cssExtraClassParent}>
          <iframe className={cssExtraClass} {...{ id, src, title, height, width }} />
        </div>
      </TagEventTracker>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String) {
    frame(url: { eq: $url }) {
      title
      src
      urls {
        lang
        href
      }
      height
      width
      cssExtraClass
      cssExtraClassParent
      seo {
        ...SEOStructureFragment
      }
    }
  }
`;

export default Frame;
